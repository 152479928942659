// src/components/MobileNavigation.tsx

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import { useNavigate, useLocation } from 'react-router-dom';

interface MobileNavigationProps {
  isLoggedIn: boolean;
  onLogout: () => void;
}

const MobileNavContainer = styled.div<{ isScrolled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.isScrolled ? '1.5rem 2rem' : '1.5rem 2rem')};
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0);
  transition: padding 0.3s ease;

  @media (min-width: 769px) {
    display: none;
  }
`;

const LogoContainer = styled.div``;

const HamburgerIcon = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 2px;
    width: 25px;
    background: white;
    margin-bottom: 4px;
    border-radius: 5px;
  }
`;

const MobileNavMenu = styled.div<{ isOpen: boolean }>`
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding-top: 4rem;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 2000;
  overflow-y: auto; /* Enable vertical scrolling */

  @media (max-width: 768px) {
    display: flex;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  z-index: 3000;
`;

const MobileNavItem = styled.div`
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  padding: 1rem;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #ff4500;
  }
`;

const MobileNavigation: React.FC<MobileNavigationProps> = ({ isLoggedIn, onLogout }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.replace('#', '');
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (window.fbq && !isMobileMenuOpen) {
      window.fbq('track', 'MobileMenuOpen');
    }
  };

  const handleNavigation = (hashOrPath: string, isRoute: boolean = false) => {
    if (isRoute) {
      // Directly navigate to the route
      navigate(hashOrPath);
    } else if (location.pathname !== '/') {
      // Navigate to `/` and include the hash
      navigate(`/#${hashOrPath}`);
    } else {
      // If on the home page, directly scroll to the hash section
      const element = document.getElementById(hashOrPath);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        window.location.hash = `#${hashOrPath}`; // Update hash if element not found
      }
    }
    setIsMobileMenuOpen(false); // Close the mobile menu
  };

  const renderMobileNavItems = () => {
    const commonNavItems = [
      { id: 'home', label: 'Home', hash: 'home' },
      { id: 'pricing', label: 'Pricing', hash: 'pricing' },
      { id: 'faq', label: 'FAQ', hash: 'faq' },
      { id: 'about-us', label: 'About Us', path: '/about-us' },
      { id: 'help-center', label: 'Help Center', path: '/help-center' },
      { id: 'download', label: 'Download', path: '/download' },
    ];

    return (
      <>
        {isLoggedIn ? (
          <>
            <MobileNavItem onClick={() => handleNavigation('/account', true)}>Account</MobileNavItem>
            {commonNavItems.map((item) =>
              item.hash ? (
                <MobileNavItem key={item.id} onClick={() => handleNavigation(item.hash)}>
                  {item.label}
                </MobileNavItem>
              ) : (
                <MobileNavItem
                  key={item.id}
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    navigate(item.path || '/');
                  }}
                >
                  {item.label}
                </MobileNavItem>
              )
            )}
            <MobileNavItem onClick={onLogout}>Logout</MobileNavItem>
          </>
        ) : (
          <>
            <MobileNavItem onClick={() => navigate('/login')}>Login</MobileNavItem>
            {commonNavItems.map((item) =>
              item.hash ? (
                <MobileNavItem key={item.id} onClick={() => handleNavigation(item.hash)}>
                  {item.label}
                </MobileNavItem>
              ) : (
                <MobileNavItem
                  key={item.id}
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    navigate(item.path || '/');
                  }}
                >
                  {item.label}
                </MobileNavItem>
              )
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <MobileNavContainer isScrolled={isScrolled}>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <HamburgerIcon onClick={toggleMobileMenu}>
          <span></span>
          <span></span>
          <span></span>
        </HamburgerIcon>
      </MobileNavContainer>
      {isMobileMenuOpen && (
        <MobileNavMenu isOpen={isMobileMenuOpen}>
          <CloseButton onClick={() => setIsMobileMenuOpen(false)}>&times;</CloseButton>
          {renderMobileNavItems()}
        </MobileNavMenu>
      )}
    </>
  );
};

export default MobileNavigation;

