// src/components/admin/AdminUsers.tsx

import React from 'react';
import '../../styles/admin.css';

const AdminUsers: React.FC = () => {
  return (
    <div className="admin-users">
      <h1>User Management</h1>
      <p>This is the user management page.</p>
    </div>
  );
};

export default AdminUsers;
