// src/components/admin/AdminDashboard.tsx

import React from 'react';
import '../../styles/admin.css';

const AdminDashboard: React.FC = () => {
  return (
    <div className="admin-dashboard">
      <h1>Dashboard</h1>
      <div className="admin-metrics">
        <div className="admin-metric-card">
          <h2>Total Users</h2>
          <p>0</p>
        </div>
        <div className="admin-metric-card">
          <h2>Active Licenses</h2>
          <p>0</p>
        </div>
        <div className="admin-metric-card">
          <h2>Active Trials</h2>
          <p>0</p>
        </div>
      </div>
      <div className="admin-recent-activities">
        <h2>Recent Activities</h2>
        <ul>
          <li>No recent activities.</li>
        </ul>
      </div>
    </div>
  );
};

export default AdminDashboard;
