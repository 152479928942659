// src/components/admin/AdminLayout.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/admin.css';

interface AdminLayoutProps {
  children: React.ReactNode;
  isLoggedIn: boolean;
  onLogout: () => void;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children, isLoggedIn, onLogout }) => {
  return (
    <div className="admin-container">
      <aside className="admin-sidebar">
        <div className="admin-logo">
          <Link to="/admin/dashboard">Admin Panel</Link>
        </div>
        <nav className="admin-nav">
          <ul>
            <li>
              <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/admin/users">User Management</Link>
            </li>
            <li>
              <Link to="/admin/licenses">License Management</Link>
            </li>
            <li>
              <Link to="/admin/newsletter">Newsletter Management</Link>
            </li>
            <li>
              <Link to="/admin/terms">Terms Acceptance</Link>
            </li>
            <li>
              <Link to="/admin/emails">Email Campaigns</Link>
            </li>
            <li>
              <Link to="/admin/logs">Audit Logs</Link>
            </li>
            <li>
              <Link to="/admin/settings">Settings</Link>
            </li>
          </ul>
        </nav>
      </aside>
      <main className="admin-main">
        <header className="admin-header">
          <div className="admin-header-right">
            {isLoggedIn ? (
              <button onClick={onLogout} className="admin-logout-button">
                Logout
              </button>
            ) : (
              <Link to="/login" className="admin-login-link">
                Login
              </Link>
            )}
          </div>
        </header>
        <div className="admin-content">{children}</div>
      </main>
    </div>
  );
};

export default AdminLayout;
