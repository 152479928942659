// src/pages/ResetPassword.tsx

import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { NotificationContext } from '../contexts/NotificationContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// Styled components (similar to your Login styles)
const ResetPasswordContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Use min-height for better mobile support */
  background-color: var(--color-background);
`;

const ResetPasswordCard = styled.div`
  background-color: var(--color-card-bg);
  padding: 2rem;
  margin: 1rem; /* Add margin for mobile spacing */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  text-align: center;
  color: var(--color-text);
  margin-bottom: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  background-color: var(--color-input-bg);
  color: var(--color-text);
  width: 100%;
  max-width: 300px;
`;

const Button = styled.button`
  padding: 0.75rem;
  background-color: var(--color-primary);
  color: var(--color-background);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  max-width: 300px;
  margin-top: 0.5rem;

  &:hover {
    background-color: var(--color-secondary);
  }
`;

const ResetPassword: React.FC = () => {
  const { showNotification } = useContext(NotificationContext);
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      showNotification('Invalid or missing token.');
      navigate('/login');
    }
  }, [token, showNotification, navigate]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      showNotification('Passwords do not match.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('token', token || '');
      formData.append('password', password);

      const response = await axios.post(
        'https://riskmaster.pro/reset_password_proxy.php',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      const resData = response.data;
      if (resData.status === 'success') {
        showNotification('Password reset successfully.');
        navigate('/login');
      } else {
        showNotification(resData.message || 'Failed to reset password.');
      }
    } catch (error) {
      showNotification('An error occurred. Please try again.');
      console.error(error);
    }
  };

  return (
    <ResetPasswordContainer>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>Your Page Title</title>
      </Helmet>
      <ResetPasswordCard>
        <Title>Reset Password</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="password"
            placeholder="New Password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Confirm New Password"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button type="submit">Reset Password</Button>
        </Form>
      </ResetPasswordCard>
    </ResetPasswordContainer>
  );
};

export default ResetPassword;