// SignUpModal.tsx

import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { NotificationContext } from '../contexts/NotificationContext';
import LoginModal from './LoginModal';
import { getValidAfid } from '../utils/afidHandler'; // Import getValidAfid


interface SignUpModalProps {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  message: string;
  onSuccess: () => void;
  onSwitchToLogin: () => void;
}

// Styled components for the modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: var(--color-card-bg);
  padding: 2rem;
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 500px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: var(--color-text);
  cursor: pointer;

  &:hover {
    color: var(--color-secondary);
  }
`;

// Reuse styles from SignUp.tsx
const Title = styled.h1`
  text-align: center;
  color: var(--color-text);
  margin-bottom: 1rem;
`;

const Message = styled.p`
  text-align: center;
  color: var(--color-text);
  margin-bottom: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  background-color: var(--color-input-bg);
  color: var(--color-text);
  width: 100%;
  max-width: 300px;
`;

const Button = styled.button`
  padding: 0.75rem;
  background-color: var(--color-primary);
  color: var(--color-background);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  max-width: 300px;
  margin-top: 0.5rem;

  &:hover {
    background-color: var(--color-secondary);
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const SignUpText = styled.p`
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: var(--color-text);

  button {
    background: none;
    border: none;
    color: var(--color-primary);
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: var(--color-secondary);
      text-decoration: underline;
    }
  }
`;

const SeparatorLine = styled.hr`
  width: 100%;
  max-width: 450px;
  border: 0;
  border-top: 1px solid var(--color-border);
  margin: 1.5rem 0;
`;

const GoogleButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  #googleSignInDivSignUp {
    width: 100%;
    max-width: 300px; /* Match the width of other buttons */
  }
`;


const SignUpModal: React.FC<SignUpModalProps> = ({
  setIsLoggedIn,
  onClose,
  message,
  onSuccess,
  onSwitchToLogin,
}) => {
  const { showNotification } = useContext(NotificationContext);
  const [fullName, setFullName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    // Initialize Google Sign-In
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: '145472120026-mavhgm62df5ud8a1p33vcif1klbts72f.apps.googleusercontent.com',
        callback: handleGoogleSignUp,
      });

      window.google.accounts.id.renderButton(
        document.getElementById('googleSignInDivSignUp'),
        { theme: 'outline', size: 'large' }
      );
    } else {
      console.error('Google API not loaded');
    }
  }, []);

  const handleGoogleSignUp = async (response: any) => {
    try {
      const googleToken = response.credential;

      // Retrieve valid afid from local storage
      const afid = getValidAfid();

      // Send the token and afid to the backend for verification and sign-up
      const res = await axios.post('https://riskmaster.pro/google_login_proxy.php', {
        token: googleToken,
        afid: afid, // Include afid if available
      });

      const resData = res.data;

      if (resData.status === 'success') {
        localStorage.setItem('token', resData.token);
        setIsLoggedIn(true);

        if (resData.new_user) {
          showNotification('Signed up successfully with Google.');
        } else {
          showNotification('Logged in successfully with Google.');
        }

        onSuccess();
      } else {
        showNotification(resData.message || 'Google sign-up failed.');
      }
    } catch (error) {
      showNotification('An error occurred during Google sign-up. Please try again.');
      console.error('Google sign-up error:', error);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      showNotification("Passwords don't match.");
      return;
    }

    setIsSubmitting(true);

    if (window.fbq) {
      window.fbq('track', 'SignupAttempt');
    }

    try {
      // Retrieve valid afid from local storage
      const afid = getValidAfid();

      // Signup API call
      const signupResponse = await axios.post(
        'https://riskmaster.pro/signup_proxy.php',
        { fullName, email, password, afid }, // Include afid if available
        { headers: { 'Content-Type': 'application/json' } }
      );

      const signupData = signupResponse.data;

      if (signupData.status === 'success') {
        showNotification('Signup successful! Please check your email to confirm.');

        if (window.fbq) {
          window.fbq('track', 'SignupSuccess');
        }

        onSuccess();
      } else {
        showNotification(signupData.message || 'Signup failed.');
      }
    } catch (error) {
      showNotification('An error occurred. Please try again.');
      console.error('Signup Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <Title>Sign Up</Title>
        <Message>{message}</Message>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Full Name"
            required
          />
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <Input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm Password"
            required
          />
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Sign Up'}
          </Button>
        </Form>
        <SignUpText>
          Already have an account?{' '}
          <button onClick={onSwitchToLogin}>Login</button>
        </SignUpText>
        <SeparatorLine />
        <GoogleButtonWrapper>
          <div id="googleSignInDivSignUp"></div>
        </GoogleButtonWrapper>
      </ModalContent>
    </ModalOverlay>
  );
};

export default SignUpModal;