// src/components/Navigation.tsx

import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Logo from './Logo';
import MobileNavigation from './MobileNavigation';
import { Link, useLocation } from 'react-router-dom';

interface NavigationProps {
  activeSection: string;
  handleScroll: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  isLoggedIn: boolean;
  onLogout: () => void;
}

interface NavItemType {
  id: string;
  label: string;
  isExternal?: boolean;
}

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const NavContainer = styled.nav<{ isAboveFeaturesSection: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  padding: 1.5rem 3rem 1rem 2rem;
  z-index: 1000;
  animation: ${(props) => (props.isAboveFeaturesSection ? fadeIn : fadeOut)} 0.5s forwards;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  pointer-events: ${(props) => (props.isAboveFeaturesSection ? 'auto' : 'none')};
  user-select: none;

  @media (max-width: 768px) {
    display: none;
  }
`;

const TopNavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  margin-right: 2rem;
`;

const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 75rem;
  margin: 0;
  height: 100%;
`;

const NavItem = styled.li<{ index: number }>`
  position: relative;
  margin-right: 2rem;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const NavLink = styled.a<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? '#FF4500' : '#CCCCCC')};
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  padding: 0.625rem 0.9375rem;

  &:hover {
    color: #ff4500;
    text-decoration: none;
  }
`;

const VerticalLine = styled.div`
  height: 4rem;
  width: 2px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0)
  );
  margin-right: 1rem;
  user-select: none;
`;

const AuthContainer = styled.div<{ isAboveFeaturesSection: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.isAboveFeaturesSection ? 1 : 0)};
  transition: opacity 0.5s ease;
  pointer-events: ${(props) => (props.isAboveFeaturesSection ? 'auto' : 'none')};
`;

const AuthButton = styled.button`
  color: #cccccc;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  padding: 0.625rem 0.9375rem;
  margin-left: 1rem;
  user-select: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: inherit;

  &:hover {
    color: #ff4500;
    text-decoration: none;
  }
`;

const HorizontalLine = styled.div`
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.125),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  margin-top: 0.65em;
  align-self: flex-start;
  user-select: none;
`;

const SideNavContainer = styled.nav<{ isAboveFeaturesSection: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  padding: 1rem;
  z-index: 1000;
  display: ${(props) => (props.isAboveFeaturesSection ? 'none' : 'block')};
  animation: ${fadeIn} 0.5s forwards;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SideNavList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SideNavItem = styled.li`
  margin-bottom: 1rem;
`;

const SideNavLink = styled(StyledLink)`
  color: #cccccc;
  font-weight: bold;
  transition: color 0.3s ease;
  text-decoration: none;

  &:hover {
    color: #ff4500;
    text-decoration: none;
  }
`;

const Navigation: React.FC<NavigationProps> = ({
  activeSection,
  handleScroll,
  isLoggedIn,
  onLogout,
}) => {
  const [isAboveFeaturesSection, setIsAboveFeaturesSection] = useState(true);
  const [lineWidth, setLineWidth] = useState(0);
  const navListRef = useRef<HTMLUListElement>(null);
  const location = useLocation();

  useEffect(() => {
    const handleScrollEvent = () => {
      const featuresSection = document.getElementById('features');
      if (featuresSection) {
        const rect = featuresSection.getBoundingClientRect();
        setIsAboveFeaturesSection(rect.top > 0);
      }
    };

    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    const updateLineWidth = () => {
      if (navListRef.current) {
        const lastItem = navListRef.current.querySelector('li:last-child');
        if (lastItem) {
          const lastItemRight = lastItem.getBoundingClientRect().right;
          const containerLeft =
            navListRef.current.closest('nav')?.getBoundingClientRect().left || 0;
          setLineWidth(lastItemRight - containerLeft);
        }
      }
    };

    updateLineWidth();
    window.addEventListener('resize', updateLineWidth);
    return () => window.removeEventListener('resize', updateLineWidth);
  }, []);

  // Define the type of topNavItems using the NavItemType interface
  let topNavItems: NavItemType[] = [];
  let sideNavItems: NavItemType[] = [];

  // List of legal routes
  const legalRoutes = ['/terms-of-service', '/privacy-policy', '/disclaimer'];

  if (legalRoutes.includes(location.pathname)) {
    topNavItems = [
      { id: 'home', label: 'Home' },
      { id: 'download', label: 'Download', isExternal: true },
      { id: 'help-center', label: 'Help Center', isExternal: true },
      { id: 'about-us', label: 'About Us', isExternal: true },
    ];
    sideNavItems = [...topNavItems];
  } else if (location.pathname === '/help-center') {
    topNavItems = [
      { id: 'home', label: 'Home' },
      { id: 'about-us', label: 'About Us', isExternal: true },
      { id: 'download', label: 'Download', isExternal: true },
    ];
    sideNavItems = [...topNavItems];
  } else if (location.pathname === '/about-us') {
    topNavItems = [
      { id: 'home', label: 'Home' },
      { id: 'help-center', label: 'Help Center', isExternal: true },
      { id: 'download', label: 'Download', isExternal: true },
    ];
    sideNavItems = [...topNavItems];
  } else if (location.pathname === '/download') {
    topNavItems = [
      { id: 'home', label: 'Home' },
      { id: 'help-center', label: 'Help Center', isExternal: true },
      { id: 'about-us', label: 'About Us', isExternal: true },
    ];
    sideNavItems = [...topNavItems];
  } else {
    topNavItems = [
      { id: 'home', label: 'Home' },
      { id: 'features', label: 'Features' },
      { id: 'guide', label: 'Guide' },
      { id: 'pricing', label: 'Pricing' },
      { id: 'faq', label: 'FAQ' },
      { id: 'newsletter', label: 'Newsletter' },
      { id: 'about-us', label: 'About Us', isExternal: true },
      { id: 'help-center', label: 'Help Center', isExternal: true },
      { id: 'download', label: 'Download', isExternal: true },
    ];
    sideNavItems = [...topNavItems];
  }

  const handleNavClick = (id: string, isExternal?: boolean) => {
    // Meta Pixel: Track Navigation Click
    if (window.fbq) {
      window.fbq('track', 'PageNavigate', { destination: id });
    }
  };

  const renderTopNavItems = () => (
    <>
      {topNavItems.map((item, index) => (
        <NavItem key={item.id} index={index}>
          <StyledLink
            to={item.isExternal ? `/${item.id}` : item.id === 'home' ? '/' : `#${item.id}`}
            onClick={(e) => {
              handleNavClick(item.id, item.isExternal);
              if (item.id === 'home') {
                if (location.pathname === '/') {
                  e.preventDefault();
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }
              } else if (!item.isExternal) {
                e.preventDefault();
                const element = document.getElementById(item.id);
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth' });
                }
              }
              handleScroll(e);
            }}
          >
            {item.label}
          </StyledLink>
        </NavItem>
      ))}
    </>
  );

  const renderSideNavItems = () =>
    sideNavItems.map((item) => (
      <SideNavItem key={item.id}>
        <SideNavLink
          to={
            item.isExternal
              ? `/${item.id}`
              : item.id === 'home'
              ? '/'
              : `#${item.id}`
          }
          onClick={(e) => {
            if (item.id === 'home') {
              if (location.pathname === '/') {
                e.preventDefault();
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }
            } else if (!item.isExternal) {
              e.preventDefault();
              const element = document.getElementById(item.id);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }
            handleScroll(e);
          }}
        >
          {item.label}
        </SideNavLink>
      </SideNavItem>
    ));

  const renderAuthButton = () => {
    if (isLoggedIn) {
      return (
        <>
          <AuthButton
            onClick={() => {
              window.fbq && window.fbq('track', 'NavAccountAccess');
            }}
          >
            <StyledLink to="/account">Account</StyledLink>
          </AuthButton>
          <AuthButton
            onClick={() => {
              onLogout();
              window.fbq && window.fbq('track', 'NavLogoutClick');
            }}
          >
            Logout
          </AuthButton>
        </>
      );
    }
    return (
      <AuthButton
        onClick={() => {
          window.fbq && window.fbq('track', 'NavLoginClick');
        }}
      >
        <StyledLink to="/login">Login</StyledLink>
      </AuthButton>
    );
  };

  return (
    <>
      <NavContainer isAboveFeaturesSection={isAboveFeaturesSection}>
        <TopNavContent>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LogoContainer>
              <Logo />
            </LogoContainer>
            <NavList ref={navListRef}>{renderTopNavItems()}</NavList>
          </div>
          <AuthContainer isAboveFeaturesSection={isAboveFeaturesSection}>
            <VerticalLine />
            {renderAuthButton()}
          </AuthContainer>
        </TopNavContent>
        <HorizontalLine style={{ width: `${lineWidth}px` }} />
      </NavContainer>
      <SideNavContainer isAboveFeaturesSection={isAboveFeaturesSection}>
        <SideNavList>{renderSideNavItems()}</SideNavList>
      </SideNavContainer>
      <MobileNavigation isLoggedIn={isLoggedIn} onLogout={onLogout} />
    </>
  );
};

export default Navigation;
