// src/pages/Pricing.tsx

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TermsAcceptance from './TermsAcceptance';
import FreeTrialButton from '../components/FreeTrialButton';
import { NotificationContext } from '../contexts/NotificationContext';
import { jwtDecode } from 'jwt-decode';
import useIsMobile from '../hooks/useIsMobile';
import SignUpModal from '../components/SignUpModal';
import LoginModal from '../components/LoginModal';
import { getValidAfid } from '../utils/afidHandler';


const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  margin-bottom: 4rem;
`;

const PageBackground = styled.div`
  background-color: var(--color-section-4-bg);
  padding: 4rem 0;
`;

const Title = styled.h2`
  color: white;
  text-align: center;
  margin-bottom: 2rem;
  user-select: none;
  font-size: 2rem;
`;

const CardsContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  justify-content: center;
  align-items: stretch;
  gap: 2rem;
`;

const Card = styled.div`
  user-select: none;
  background-color: var(--color-card-bg);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  text-align: center;
  flex: 1;
`;

const PriceContainer = styled.div<{ isMobile: boolean }>`
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'baseline')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;

const Price = styled.h2`
  font-size: 3rem;
  color: white;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-right: 0;
  }
`;

const AnnualTotal = styled.span`
  font-size: 1.5rem;
  color: var(--color-text-secondary);

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const PriceSubtext = styled.p`
  color: var(--color-text-secondary);
  margin-bottom: 1rem;
`;

const SubscribeButton = styled.button`
  background-color: #ff4500;
  color: white;
  padding: 1rem 2rem;
  font-size: 1.25rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #ff6347;
    transform: scale(1.05);
  }
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
`;

const FreeTrialContainer = styled.div`
  margin-top: 3rem;
  text-align: center;
`;

interface PlanCardProps {
  title: string;
  price: number;
  totalPrice?: number;
  priceSubtext: string;
  onSubscribeClick: () => void;
  isMobile: boolean;
}

const PlanCard: React.FC<PlanCardProps> = ({
  title,
  price,
  totalPrice,
  priceSubtext,
  onSubscribeClick,
  isMobile,
}) => {
  return (
    <Card>
      <h3>{title}</h3>
      <PriceContainer isMobile={isMobile}>
        <Price>${price}</Price>
        {totalPrice && <AnnualTotal>(${totalPrice.toFixed(2)}/year)</AnnualTotal>}
      </PriceContainer>
      <PriceSubtext>{priceSubtext}</PriceSubtext>
      <ButtonContainer>
        <SubscribeButton onClick={onSubscribeClick}>Subscribe Now</SubscribeButton>
      </ButtonContainer>
    </Card>
  );
};

const Pricing: React.FC = () => {
  const [modalType, setModalType] = useState<'signup' | 'login' | null>(null);
  const [modalMessage, setModalMessage] = useState('');
  const [isAnnualPlan, setIsAnnualPlan] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const navigate = useNavigate();
  const monthlyLink = 'https://dcm.secureteam.net/checkout/show/41/53?showCoupon=true';
  const annualLink = 'https://dcm.secureteam.net/checkout/show/41/54?showCoupon=true';
  const termsVersion = '1.0';
  const { showNotification } = useContext(NotificationContext);
  const isMobile = useIsMobile();

  const isTokenValid = (token: string | null) => {
    if (!token) return false;
    try {
      const decoded: any = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp && decoded.exp > currentTime;
    } catch (error) {
      console.error('Failed to decode token:', error);
      return false;
    }
  };

  const fetchUserData = async () => {
    const token = localStorage.getItem('token');

    if (!isTokenValid(token)) {
      localStorage.removeItem('token');
      setUserData(null);
      setIsLoggedIn(false);
      return;
    }

    if (token) {
      try {
        const response = await fetch('https://riskmaster.pro/fetch_user_data_proxy.php', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 401) {
          localStorage.removeItem('token');
          setUserData(null);
          setIsLoggedIn(false);
          return;
        }

        const data = await response.json();
        if (data.status === 'success') {
          setUserData(data.user);
          setIsLoggedIn(true);
        } else {
          setErrorMessage(data.message);
        }
      } catch (error) {
        setErrorMessage('Failed to fetch user data');
        console.error(error);
      }
    } else {
      setUserData(null);
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [isLoggedIn]);

  const checkTermsAcceptance = async () => {
    try {
      const response = await fetch(
        'https://riskmaster.pro/check_terms_acceptance_proxy.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userData.id,
            version: termsVersion,
          }),
        }
      );

      const data = await response.json();

      if (data.status === 'accepted') {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Failed to check ToS acceptance:', error);
      showNotification('Failed to check Terms of Service acceptance.');
      return false;
    }
  };

  const handleSubscribeClick = (isAnnual: boolean) => async () => {
    setIsAnnualPlan(isAnnual);
    const token = localStorage.getItem('token');

    if (!isTokenValid(token)) {
      localStorage.removeItem('token');
      setUserData(null);
      setIsLoggedIn(false);
      setModalMessage('Please log in or sign up to subscribe.');
      setModalType('signup');
      return;
    }

    if (userData) {
      const hasAcceptedTerms = await checkTermsAcceptance();
      if (!hasAcceptedTerms) {
        setShowTerms(true);
        return;
      }
    }

    if (window.fbq) {
      window.fbq('track', 'SubscribeClick', {
        subscriptionType: isAnnual ? 'Annual' : 'Monthly',
      });
    }

    // Determine the affiliate ID to use
    let afid: string | null = null;
    if (userData && userData.affiliateId) {
      afid = userData.affiliateId.toString();
    } else {
      afid = getValidAfid();
    }

    // Base redirect link
    let redirectLink = isAnnual ? annualLink : monthlyLink;

    // Append afid if valid
    if (afid) {
      redirectLink += `&afid=${afid}`;
    }

    window.location.href = redirectLink;
  };

  const handleModalClose = () => {
    setModalType(null);
  };

  const handleModalSuccess = async () => {
    setModalType(null);
    setIsLoggedIn(true);
    await fetchUserData();

    // Force a page reload
    window.location.reload();
  };

  const handleTermsAccepted = () => {
    setShowTerms(false);

    // Get the valid afid
    const afid = getValidAfid();

    // Base redirect link
    let redirectLink = isAnnualPlan ? annualLink : monthlyLink;

    // Append afid if valid
    if (afid) {
      redirectLink += `&afid=${afid}`;
    }

    window.location.href = redirectLink;
  };

  const handleExit = () => {
    setShowTerms(false);
  };

  return (
    <PageBackground>
      <Container>
        <Title>Pricing Plans</Title>
        <CardsContainer isMobile={isMobile}>
          <PlanCard
            title="Monthly Plan"
            price={29.99}
            totalPrice={29.99 * 12}
            priceSubtext="per month"
            onSubscribeClick={handleSubscribeClick(false)}
            isMobile={isMobile}
          />
          <PlanCard
            title="Annual Plan"
            price={24.99}
            totalPrice={24.99 * 12}
            priceSubtext="per month, billed annually"
            onSubscribeClick={handleSubscribeClick(true)}
            isMobile={isMobile}
          />
        </CardsContainer>

        <FreeTrialContainer>
          <h3 style={{ color: 'white' }}>Not Ready to Commit?</h3>
          <FreeTrialButton />
        </FreeTrialContainer>

        {modalType === 'signup' && (
          <SignUpModal
            setIsLoggedIn={setIsLoggedIn}
            onClose={handleModalClose}
            message={modalMessage}
            onSuccess={handleModalSuccess}
            onSwitchToLogin={() => setModalType('login')}
          />
        )}

        {modalType === 'login' && (
          <LoginModal
            setIsLoggedIn={setIsLoggedIn}
            onClose={handleModalClose}
            onSwitchToSignUp={() => setModalType('signup')}
            onSuccess={handleModalSuccess}
          />
        )}

        {showTerms && userData && (
          <TermsAcceptance
            userId={userData.id}
            version={termsVersion}
            onAccepted={handleTermsAccepted}
            onExit={handleExit}
          />
        )}
      </Container>
    </PageBackground>
  );
};

export default Pricing;