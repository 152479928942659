// LoginModal.tsx

import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { NotificationContext } from '../contexts/NotificationContext';
import { Link, useNavigate } from 'react-router-dom';
import { getValidAfid } from '../utils/afidHandler'; // Import getValidAfid

interface LoginModalProps {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  onSwitchToSignUp: () => void;
  onSuccess: () => void;
}

// Styled components for the modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: var(--color-card-bg);
  padding: 2rem;
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 500px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: var(--color-text);
  cursor: pointer;

  &:hover {
    color: var(--color-secondary);
  }
`;

// Reuse styles from Login.tsx
const Title = styled.h1`
  text-align: center;
  color: var(--color-text);
  margin-bottom: 1rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  background-color: var(--color-input-bg);
  color: var(--color-text);
  width: 100%;
  max-width: 300px;
`;

const Button = styled.button`
  padding: 0.75rem;
  background-color: var(--color-primary);
  color: var(--color-background);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  max-width: 300px;
  margin-top: 0.5rem;

  &:hover {
    background-color: var(--color-secondary);
  }
`;

const ResendButton = styled(Button)`
  background-color: var(--color-secondary);

  &:hover {
    background-color: var(--color-primary);
  }
`;

const SignUpText = styled.p`
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: var(--color-text);

  button {
    background: none;
    border: none;
    color: var(--color-primary);
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: var(--color-secondary);
      text-decoration: underline;
    }
  }
`;

const SeparatorLine = styled.hr`
  width: 100%;
  max-width: 550px;
  border: 0;
  border-top: 1px solid var(--color-border);
  margin: 1.5rem 0;
`;

const GoogleButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  #googleSignInDivLogin {
    width: 100%;
    max-width: 300px;
  }
`;

const LoginModal: React.FC<LoginModalProps> = ({
  setIsLoggedIn,
  onClose,
  onSwitchToSignUp,
  onSuccess,
}) => {
  const navigate = useNavigate();
  const { showNotification } = useContext(NotificationContext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showResend, setShowResend] = useState<boolean>(false);

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: '145472120026-mavhgm62df5ud8a1p33vcif1klbts72f.apps.googleusercontent.com',
        callback: handleGoogleLogin,
      });

      window.google.accounts.id.renderButton(
        document.getElementById('googleSignInDivLogin'),
        { theme: 'outline', size: 'large' }
      );
    } else {
      console.error('Google API not loaded');
    }
  }, []);

  const handleGoogleLogin = async (response: any) => {
    try {
      const googleToken = response.credential;

      // Retrieve valid afid from local storage
      const afid = getValidAfid();

      // Send the token and afid to the backend for verification and login
      const res = await axios.post('https://riskmaster.pro/google_login_proxy.php', {
        token: googleToken,
        afid: afid, // Include afid if available
      });

      const resData = res.data;
      if (resData.status === 'success') {
        localStorage.setItem('token', resData.token);
        setIsLoggedIn(true);

        if (resData.new_user) {
          showNotification('Signed up successfully with Google.');
        } else {
          showNotification('Logged in successfully with Google.');
        }

        onSuccess();
        navigate('/');
      } else {
        showNotification(resData.message || 'Google login failed.');
      }
    } catch (error) {
      showNotification('An error occurred during Google login. Please try again.');
      console.error('Google login error:', error);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (window.fbq) {
      window.fbq('track', 'LoginAttempt');
    }

    try {
      // Retrieve valid afid from local storage
      const afid = getValidAfid();

      // Prepare the login data, including afid if available
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
      if (afid) {
        formData.append('afid', afid);
      }

      const response = await axios.post(
        'https://riskmaster.pro/login_proxy.php',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      const resData = response.data;
      if (resData.status === 'success') {
        localStorage.setItem('token', resData.token);
        setIsLoggedIn(true);
        showNotification('Logged in successfully.');
        onSuccess();
      } else {
        showNotification(resData.message || 'Login failed.');
        if (resData.message && resData.message.includes('not confirmed')) {
          setShowResend(true);
        }
      }
    } catch (error) {
      showNotification('An error occurred during login. Please try again.');
      console.error(error);
    }
  };

  const handleResendConfirmation = async () => {
    if (window.fbq) {
      window.fbq('track', 'ResendConfirmation');
    }

    try {
      const formData = new FormData();
      formData.append('email', email);

      const response = await axios.post(
        'https://riskmaster.pro/resend_confirmation_proxy.php',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      const resData = response.data;
      if (resData.status === 'success') {
        showNotification('Confirmation email resent. Please check your inbox.');
      } else {
        showNotification(resData.message || 'Failed to resend confirmation email.');
      }
    } catch (error) {
      showNotification('An error occurred while resending the confirmation email. Please try again.');
      console.error(error);
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <Title>Login to RiskMaster</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder="Email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit">Log In</Button>
          {showResend && (
            <ResendButton type="button" onClick={handleResendConfirmation}>
              Resend Confirmation Email
            </ResendButton>
          )}
        </Form>
        <SignUpText>
          Don't have an account?{' '}
          <button onClick={onSwitchToSignUp}>Sign Up</button>
        </SignUpText>
        <SignUpText>
          Forgot your password?{' '}
          <a href="/forgot-password">Reset it here</a>
        </SignUpText>
        <SeparatorLine />
        <GoogleButtonWrapper>
          <div id="googleSignInDivLogin"></div>
        </GoogleButtonWrapper>
      </ModalContent>
    </ModalOverlay>
  );
};

export default LoginModal;
