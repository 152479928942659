// src/App.tsx

import React, { useRef, useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './components/Layout';
import Home from './pages/Home';
import Features from './components/Features';
import Pricing from './components/Pricing';
import FAQ from './components/FAQ';
import Newsletter from './components/Newsletter';
import Login from './pages/Login';
import Guide from './components/guide/Guide';
import NinjaTraderSection from './components/NinjaTraderSection';
import HelpCenter from './pages/HelpCenter';
import Signup from './pages/SignUp';
import Account from './pages/Account';
import ProfileEdit from './pages/ProfileEdit';
import AboutUs from './pages/AboutUs';
import Download from './pages/Download';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import DisclaimerPage from './pages/DisclaimerPage';
import NotificationProvider from './contexts/NotificationContext';
import AdminLayout from './components/admin/AdminLayout';
import AdminDashboard from './components/admin/AdminDashboard';
import AdminUsers from './components/admin/AdminUsers';
import './styles/main.css';

// Import jwt-decode to decode JWT tokens
import { jwtDecode } from 'jwt-decode'; // Corrected import

// Declare fbq on the window object for TypeScript
declare global {
  interface Window {
    fbq: any;
  }
}

const initializeMetaPixel = () => {
  (function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = true;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = true;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

  window.fbq('init', '1789580281850126');
  window.fbq('track', 'PageView');
};

const App: React.FC = () => {
  const [isGuideActive, setIsGuideActive] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const [shouldStartGuide, setShouldStartGuide] = useState(false);
  const guideRef = useRef<HTMLDivElement>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    initializeMetaPixel();
  }, []);

  // Function to check if the token is valid
  const isTokenValid = (token: string | null) => {
    if (!token) return false;
    try {
      const decoded: any = jwtDecode(token);
      const currentTime = Date.now() / 1000; // in seconds
      if (decoded.exp && decoded.exp > currentTime) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Failed to decode token:', error);
      return false;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (isTokenValid(token)) {
      setIsLoggedIn(true);
    } else {
      // Token is invalid or expired, remove it
      localStorage.removeItem('token');
      setIsLoggedIn(false);
    }
  }, []);

  const handleScroll = useCallback(() => {
    const sections = ['home', 'features', 'guide', 'pricing', 'faq', 'newsletter', 'help-center'];
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (windowHeight + scrollTop >= documentHeight - 100) {
      setActiveSection('newsletter');
    } else {
      const currentSection = sections.find((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });

      if (currentSection) {
        setActiveSection(currentSection);
        if (currentSection === 'guide' && !isGuideActive) {
          setIsGuideActive(true);
          setShouldStartGuide(true);
        }
      }
    }
  }, [isGuideActive]);

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <HelmetProvider> {/* Wrap the application with HelmetProvider */}
      <NotificationProvider>
        <Router>
          <Routes>
            <Route
              path="/:afid?"
              element={
                <Layout
                  activeSection={activeSection}
                  handleScroll={handleScroll}
                  isLoggedIn={isLoggedIn}
                  onLogout={handleLogout}
                >
                  <section id="home">
                    <Home />
                  </section>
                  <section id="features">
                    <Features />
                  </section>
                  <section id="guide" ref={guideRef}>
                    <Guide startAnimation={isGuideActive} shouldStartGuide={shouldStartGuide} />
                  </section>
                  <section id="pricing">
                    <Pricing />
                  </section>
                  <section id="faq">
                    <FAQ />
                  </section>
                  <NinjaTraderSection />
                  <section id="newsletter">
                    <Newsletter />
                  </section>
                </Layout>
              }
            />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/signup" element={<Signup setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/account" element={<Account setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/profile-edit" element={<ProfileEdit />} />
            <Route
              path="/help-center"
              element={<HelpCenter isLoggedIn={isLoggedIn} onLogout={handleLogout} />}
            />
            <Route
              path="/about-us"
              element={<AboutUs isLoggedIn={isLoggedIn} onLogout={handleLogout} />}
            />
            <Route
              path="/download"
              element={<Download isLoggedIn={isLoggedIn} onLogout={handleLogout} />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
            <Route path="/disclaimer" element={<DisclaimerPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            {/* Admin Routes */}
            <Route
              path="/admin/*"
              element={
                <AdminLayout isLoggedIn={isLoggedIn} onLogout={handleLogout}>
                  <Routes>
                    <Route path="dashboard" element={<AdminDashboard />} />
                    <Route path="users" element={<AdminUsers />} />
                    {/* Additional admin routes can be added here */}
                  </Routes>
                </AdminLayout>
              }
            />
          </Routes>
        </Router>
      </NotificationProvider>
    </HelmetProvider>
  );
};

export default App;
