// src/pages/ForgotPassword.tsx

import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { NotificationContext } from '../contexts/NotificationContext';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// Styled components (similar to your Login styles)
const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Use min-height for better mobile support */
  background-color: var(--color-background);
`;

const ForgotPasswordCard = styled.div`
  background-color: var(--color-card-bg);
  padding: 2rem;
  margin: 1rem; /* Add margin for mobile spacing */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  text-align: center;
  color: var(--color-text);
  margin-bottom: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  background-color: var(--color-input-bg);
  color: var(--color-text);
  width: 100%;
  max-width: 300px;
`;

const Button = styled.button`
  padding: 0.75rem;
  background-color: var(--color-primary);
  color: var(--color-background);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  max-width: 300px;
  margin-top: 0.5rem;

  &:hover {
    background-color: var(--color-secondary);
  }
`;

const BackToLoginText = styled.p`
  text-align: center;
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: var(--color-text);

  a {
    color: var(--color-primary);
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: var(--color-secondary);
      text-decoration: underline;
    }
  }
`;

const ForgotPassword: React.FC = () => {
  const { showNotification } = useContext(NotificationContext);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append('email', email);

      const response = await axios.post(
        'https://riskmaster.pro/forgot_password_proxy.php',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      const resData = response.data;
      if (resData.status === 'success') {
        showNotification('Password reset email sent. Please check your inbox.');
        navigate('/login');
      } else {
        showNotification(resData.message || 'Failed to send password reset email.');
      }
    } catch (error) {
      showNotification('An error occurred. Please try again.');
      console.error(error);
    }
  };

  return (
    <ForgotPasswordContainer>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>Your Page Title</title>
      </Helmet>
      <ForgotPasswordCard>
        <Title>Forgot Password</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder="Enter your email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button type="submit">Send Reset Link</Button>
        </Form>
        <BackToLoginText>
          Remembered your password? <Link to="/login">Log In</Link>
        </BackToLoginText>
      </ForgotPasswordCard>
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;