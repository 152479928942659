// src/pages/Home.tsx

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom'; // Import useLocation
import BuyNowButton from '../components/BuyNowButton';
import FreeTrialButton from '../components/FreeTrialButton';
import { Helmet } from 'react-helmet-async';
import { storeAfid } from '../utils/afidHandler';

const HomeContainer = styled.div`
  background-color: var(--color-section-1-bg);
  color: white;
  padding: 2rem 0;
  user-select: none;

  @media (max-width: 768px) {
    padding: 0; // Remove vertical padding for mobile
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 1rem; // Reduce padding even further for mobile
  }
`;

const TitleSection = styled.div`
  width: 100%;
  text-align: left; // Changed from center to left
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    text-align: center; // Center-align text for mobile
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 4rem; // Very large font size
  margin-bottom: 2rem;
  text-align: left; // Explicitly set text alignment to left
  max-width: 80%; // Limit the width of the title for better readability

  @media (max-width: 768px) {
    font-size: 2.5rem; // Reduce font size for mobile
    text-align: center; // Center-align text for mobile
    max-width: 100%; // Allow full width for mobile
  }
`;

const GradientSpan = styled.span`
  background: linear-gradient(to right, #FF4500, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
`;

const LowerSection = styled.div`
  display: flex;
  justify-content: flex-end; // Changed from flex-end to flex-start
  align-items: flex-start; // Changed from center to flex-start
  flex-grow: 1;
  margin-top: -2rem; // Move the section up

  @media (max-width: 768px) {
    flex-direction: column; // Stack elements vertically for mobile
    align-items: center; // Center-align elements for mobile
    margin-top: 0; // Reset margin for mobile
  }
`;

const DescriptionSection = styled.div`
  max-width: 60%;
  text-align: left; // Changed from right to left
  margin-right: 5%; // Add right margin to position slightly right of center

  @media (max-width: 768px) {
    max-width: 100%; // Allow full width for mobile
    text-align: center; // Center-align text for mobile
    margin-right: 0; // Reset margin for mobile
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 2;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1rem; // Reduce font size for mobile
    line-height: 1.5; // Adjust line height for mobile
  }
`;

const Subtitle = styled.h2`
  color: #CCCCCC;
  font-size: 2rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem; // Reduce font size for mobile
    text-align: center; // Center-align text for mobile
  }
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: flex-start; // Changed from space-between to flex-start
  align-items: center;
  margin-top: 2rem;
  gap: 2rem; // Add space between NinjaTraderInfo and BuyNowButton

  @media (max-width: 768px) {
    flex-direction: column; // Stack elements vertically for mobile
    gap: 1rem; // Adjust gap for mobile
  }
`;

const NinjaTraderInfo = styled.h3`
  color: white; // Set text color to white
  font-size: 1.2rem;
  margin-top: 1rem;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1rem; // Reduce font size for mobile
    text-align: center; // Center-align text for mobile
  }
`;

const Home: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    // Extract the 'afid' from the query parameters
    const params = new URLSearchParams(location.search);
    const afid = params.get('afid');

    if (afid) {
      // Store the afid using the centralized handler
      storeAfid(afid);
      // console.log('Affiliate ID stored:', afid);
    }
  
    if (window.fbq) {
      window.fbq('track', 'ViewContent', { page: 'Home' });
    }
  }, [location.search]);

  return (
    <HomeContainer>
      <Helmet>
        <title>Risk Master - Elevate Your Trading Strategy</title>
        <meta
          name="description"
          content="Enhance your trading strategy with Risk Master. Manage risks with personalized profiles and automated safeguards to achieve consistent profits."
        />
        <link rel="canonical" href="https://www.riskmaster.pro/" />
      </Helmet>
      <ContentWrapper>
        <TitleSection>
          <Title>
            <GradientSpan>Risk Master</GradientSpan>, where discipline meets profit.
          </Title>
        </TitleSection>
        <LowerSection>
          <DescriptionSection>
            <Subtitle>Elevate your trading</Subtitle>
            <Description>
              Manage trading risks with personalized profiles and automated safeguards. Maintain control with enforced limits on contracts and daily losses.
            </Description>
            <Description>
              Embrace disciplined trading with Risk Master.
            </Description>
            <BottomRow>
              <NinjaTraderInfo>
                For NinjaTrader 8 users
              </NinjaTraderInfo>
              <BuyNowButton />
              <FreeTrialButton />
            </BottomRow>
          </DescriptionSection>
        </LowerSection>
      </ContentWrapper>
    </HomeContainer>
  );
};

export default Home;
