// src/pages/DisclaimerPage.tsx

import React from 'react';
import styled, { css } from 'styled-components';
import useIsMobile from '../hooks/useIsMobile';
import { Helmet } from 'react-helmet-async';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

interface ResponsiveProps {
  isMobile: boolean;
}

const Container = styled.div<ResponsiveProps>`
  max-width: 1200px;
  margin: 0 auto;
  padding: 12rem 2rem 2rem;
  color: var(--color-text);
  background-color: var(--color-background);
  ${({ isMobile }) =>
    isMobile &&
    css`
      text-align: center;
      padding: 0rem 2rem 2rem;
    `}
  line-height: 1.6;

  p,
  ul {
    margin-bottom: 1.5rem;
  }
`;

const Title = styled.h1<ResponsiveProps>`
  color: var(--color-primary);
  text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
  margin-bottom: 2rem;
`;

const Section = styled.section<ResponsiveProps>`
  margin-bottom: 2rem;

  h2 {
    color: var(--color-secondary);
    margin-bottom: 1rem;
    text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
  }

  p {
    text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
  }

  ul {
    text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
  }
`;

const Paragraph = styled.p<ResponsiveProps>`
  line-height: 1.6;
  margin-bottom: 1rem;
  text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
`;

const List = styled.ul<ResponsiveProps>`
  list-style-type: disc;
  ${({ isMobile }) =>
    isMobile
      ? css`
          margin-left: 0;
          padding-left: 0;
          text-align: center;
        `
      : css`
          margin-left: 1.5rem;
          padding-left: 1.5rem;
        `}

  li {
    margin-bottom: 0.5rem;
    ${({ isMobile }) =>
      isMobile &&
      css`
        text-align: center;
      `}
  }

  a {
    color: var(--color-primary);
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
      color: var(--color-secondary);
    }
  }
`;

const ContactLink = styled.a`
  color: var(--color-primary);
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
    color: var(--color-secondary);
  }
`;

const DisclaimerPage: React.FC = () => {
  const isMobile = useIsMobile();

  const handleContactClick = () => {
    window.location.href = 'mailto:info@riskmaster.pro';
  };

  return (
    <>
      <Helmet>
        <title>Disclaimer | RiskMaster</title>
        <meta
          name="description"
          content="Read the Disclaimer for RiskMaster, outlining the limitations and risks associated with using our software."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://www.riskmaster.pro/disclaimer" />
      </Helmet>
      <Navigation
        activeSection="disclaimer"
        handleScroll={() => {}}
        isLoggedIn={false} // Update this based on your auth logic
        onLogout={() => {}} // Update this based on your auth logic
      />
      <Container isMobile={isMobile}>
        <Title isMobile={isMobile}>Disclaimer</Title>

        <Section isMobile={isMobile}>
          <h2>Trading Risks and Limitations</h2>
          <Paragraph isMobile={isMobile}>
            <strong>Risk of Loss:</strong> Trading and investing in securities, commodities,
            and other financial instruments involve significant risk. Most day traders and
            investors who use formula and chart-based investing strategies lose money. The
            risk of loss in trading and investing can be substantial. You should therefore
            carefully consider whether such trading and investing activities are suitable for
            you in light of your financial condition.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>Educational Purpose:</strong> The Software provided by M.S Venture LLC
            is intended solely for educational and informational purposes. The Software is
            designed to assist users in managing risks while day trading but does not
            constitute financial advice. Decisions to buy, sell, hold, or trade in
            securities, commodities, and other investments involve risk and should be made
            based on the advice of qualified financial professionals.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>Hypothetical Performance:</strong> All content and performance data
            provided by the Software are hypothetical and selected after the fact to
            demonstrate the capabilities of the Software. Past performance does not
            guarantee future results.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>No Guarantee of Success:</strong> M.S Venture LLC makes no guarantees
            regarding the success or profitability of any trading strategy. The Software
            does not guarantee success in trading or investing and should not be relied upon
            as the sole basis for making financial decisions.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>Limitations of Liability:</strong> M.S Venture LLC shall not be liable
            for any direct, indirect, incidental, or consequential damages resulting from
            the use or inability to use the Software. The use of the Software is at the
            user's own risk. The Company shall not be liable for any damages arising from or
            related to the use of the Software.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>Third-party Risk Disclosure:</strong> Users are advised to read the
            legal documentation and risk policies of any trading platform provider,
            brokerage, or financial institution before using their services or products. For
            more information about risk disclosures and other legal notices on supported
            trading platforms, please refer to the following link:
          </Paragraph>
          <List isMobile={isMobile}>
            <li>
              <a
                href="https://ninjatrader.com/risk-disclosure-clearing/"
                target="_blank"
                rel="noopener noreferrer"
              >
                NinjaTrader
              </a>
            </li>
          </List>
        </Section>

        <Section isMobile={isMobile}>
          <h2>Contact Us</h2>
          <Paragraph isMobile={isMobile}>
            If you have any questions or concerns about this Disclaimer, please{' '}
            <ContactLink onClick={handleContactClick}>contact us</ContactLink>.
          </Paragraph>
        </Section>
      </Container>
      <Footer />
    </>
  );
};

export default DisclaimerPage;
