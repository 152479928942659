// src/pages/TermsOfServicePage.tsx

import React from 'react';
import styled, { css } from 'styled-components';
import useIsMobile from '../hooks/useIsMobile';
import { Helmet } from 'react-helmet-async';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

// Define interface for responsive props
interface ResponsiveProps {
  isMobile: boolean;
}

const TermsContainer = styled.div<ResponsiveProps>`
  max-width: 1200px;
  margin: 0 auto;
  padding: 12rem 2rem 2rem; /* Added 12rem top padding */
  color: var(--color-text);
  background-color: var(--color-background);
  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0rem 2rem 2rem; /* Added 0rem top padding for mobile */
      text-align: center;
    `}
  line-height: 1.6;

  p,
  ul {
    margin-bottom: 1.5rem;
  }
`;

const Title = styled.h1<ResponsiveProps>`
  color: var(--color-primary);
  text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
  margin-bottom: 2rem;
`;

const Section = styled.section<ResponsiveProps>`
  margin-bottom: 2rem;

  h2 {
    color: var(--color-secondary);
    margin-bottom: 1rem;
    text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
  }
`;

const Paragraph = styled.p<ResponsiveProps>`
  line-height: 1.6;
  margin-bottom: 1rem;
  text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
`;

const List = styled.ul<ResponsiveProps>`
  list-style-type: disc;
  ${({ isMobile }) =>
    isMobile
      ? css`
          margin-left: 0;
          padding-left: 0;
          text-align: center;
        `
      : css`
          margin-left: 1.5rem;
          padding-left: 1.5rem;
        `}

  li {
    margin-bottom: 0.5rem;
    ${({ isMobile }) =>
      isMobile &&
      css`
        text-align: center;
      `}
  }

  a {
    color: var(--color-primary);
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
      color: var(--color-secondary);
    }
  }
`;

const FooterLink = styled.a`
  color: var(--color-primary);
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
    color: var(--color-secondary);
  }
`;

const TermsOfServicePage: React.FC = () => {
  const isMobile = useIsMobile();

  const handleContactClick = () => {
    window.location.href = 'mailto:info@riskmaster.pro';
  };

  return (
    <>
      <Helmet>
        <title>Terms of Service | RiskMaster</title>
        <meta
          name="description"
          content="Read the Terms of Service for RiskMaster, outlining the rules and regulations for using our software."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://www.riskmaster.pro/terms-of-service" />
      </Helmet>
      <Navigation
        activeSection="terms-of-service"
        handleScroll={() => {}}
        isLoggedIn={false} // Update this based on your authentication logic
        onLogout={() => {}} // Update this based on your authentication logic
      />
      <TermsContainer isMobile={isMobile}>
        <Title isMobile={isMobile}>Terms of Service</Title>

        <Section isMobile={isMobile}>
          <h2>1. Acceptance of Terms</h2>
          <Paragraph isMobile={isMobile}>
            Welcome to M.S Venture LLC ("Company," "we," "us," or "our"). These Terms of
            Service ("Terms") govern your access to and use of our RiskMaster software and
            website ("Software"). By using the Software, you agree to comply with and be bound
            by these Terms. If you do not agree with these Terms, do not access or use the
            Software.
          </Paragraph>
        </Section>

        <Section isMobile={isMobile}>
          <h2>2. License and Use</h2>
          <Paragraph isMobile={isMobile}>
            <strong>2.1 License Grant:</strong> Upon purchase, we grant you a non-exclusive,
            non-transferable, limited license to use the Software for personal or professional use,
            subject to the terms specified herein.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>2.2 License Restrictions:</strong>
          </Paragraph>
          <List isMobile={isMobile}>
            <li>
              The license permits installation and use on up to three devices concurrently.
              Usage beyond three devices requires deactivation of one or more previously registered
              devices.
            </li>
            <li>
              Users may not copy, modify, distribute, sell, or lease any part of the Software, nor
              may they reverse-engineer or attempt to extract the source code.
            </li>
          </List>
          <Paragraph isMobile={isMobile}>
            <strong>2.3 Deactivation and Restrictions:</strong>
          </Paragraph>
          <List isMobile={isMobile}>
            <li>
              Use of the Software is restricted to active license holders. Upon license
              deactivation, users must immediately cease using the Software.
            </li>
            <li>
              Attempts to exploit the Software or bypass device restrictions may result in
              termination of the license without notice.
            </li>
          </List>
        </Section>

        <Section isMobile={isMobile}>
          <h2>3. Software Usage and Limitations</h2>
          <Paragraph isMobile={isMobile}>
            <strong>3.1 Risk and Responsibility:</strong> Users acknowledge that trading and
            investing in financial instruments carry significant risk, including the potential for
            substantial losses. The Company is not responsible for any losses incurred by users. The
            Software is provided for informational and educational purposes only and should not be
            considered financial advice.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>3.2 Hypothetical Performance Disclaimer:</strong> All performance data provided
            by the Software are hypothetical and do not guarantee future results. Past performance is
            not an indicator of future performance.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>3.3 No Guarantee of Success:</strong> M.S Venture LLC does not guarantee the
            success of any trading strategies or investments made using the Software. Users are advised
            to consult with a qualified financial professional before making any investment decisions.
          </Paragraph>
        </Section>

        <Section isMobile={isMobile}>
          <h2>4. Liability Limitations</h2>
          <Paragraph isMobile={isMobile}>
            <strong>4.1 Disclaimer of Warranties:</strong> The Software is provided "as is" and
            "as available" without any warranties, either express or implied. The Company disclaims
            all warranties, including but not limited to merchantability, fitness for a particular
            purpose, and non-infringement.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>4.2 Limitation of Liability:</strong> To the maximum extent permitted by law,
            M.S Venture LLC shall not be liable for any direct, indirect, incidental, consequential,
            or special damages arising out of or related to your use of the Software. This includes any
            loss of profits, trading losses, or damages arising from the failure of the Software.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>4.3 Indemnification:</strong> You agree to indemnify and hold harmless M.S Venture
            LLC from any claims, losses, or damages, including legal fees, arising out of your use
            of the Software, your violation of these Terms, or your violation of any third-party
            rights.
          </Paragraph>
        </Section>

        <Section isMobile={isMobile}>
          <h2>5. User Obligations</h2>
          <Paragraph isMobile={isMobile}>
            <strong>5.1 Compliance with Applicable Laws:</strong> Users agree to use the Software
            in compliance with all applicable local, state, national, and international laws.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>5.2 Prohibited Conduct:</strong> Users may not use the Software for any illegal
            activities, including but not limited to fraud, money laundering, or manipulation of
            financial markets. Users may not use the Software to attempt to circumvent or bypass the
            one-device restriction or any other security measures implemented by the Company.
          </Paragraph>
        </Section>

        <Section isMobile={isMobile}>
          <h2>6. Termination of License</h2>
          <Paragraph isMobile={isMobile}>
            <strong>6.1 Termination by User:</strong> You may terminate your license at any time by
            discontinuing the use of the Software and uninstalling it from all registered devices.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>6.2 Termination by the Company:</strong> We reserve the right to terminate or
            suspend your license without notice if you breach these Terms, engage in fraudulent
            activities, or otherwise misuse the Software.
          </Paragraph>
        </Section>

        <Section isMobile={isMobile}>
          <h2>7. Intellectual Property Rights</h2>
          <Paragraph isMobile={isMobile}>
            <strong>7.1 Ownership:</strong> All intellectual property rights, including copyrights,
            trademarks, and patents, in and to the Software are owned by M.S Venture LLC. Nothing in
            these Terms grants you any rights in the Software other than the limited license granted
            herein.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>7.2 Copyright Protection:</strong> You agree not to copy, reproduce, or distribute
            any portion of the Software without the Company's express written consent.
          </Paragraph>
        </Section>

        <Section isMobile={isMobile}>
          <h2>8. Privacy and Data Security</h2>
          <Paragraph isMobile={isMobile}>
            <strong>8.1 Privacy Policy:</strong> Our <FooterLink href="/privacy-policy">Privacy Policy</FooterLink> outlines how we collect, use, and protect your personal information. By using the Software, you agree to the terms of our Privacy Policy.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>8.2 Data Transmission:</strong> While we implement measures to secure data transmission,
            we cannot guarantee the complete security of any data sent over the internet. You acknowledge
            that you transmit information at your own risk.
          </Paragraph>
        </Section>

        <Section isMobile={isMobile}>
          <h2>9. Changes to the Terms of Service</h2>
          <Paragraph isMobile={isMobile}>
            We reserve the right to update these Terms from time to time. The updated Terms will be
            posted on our website, and users will be notified via email or on the Software. Continued
            use of the Software constitutes acceptance of the updated Terms.
          </Paragraph>
        </Section>

        <Section isMobile={isMobile}>
          <h2>10. Governing Law and Dispute Resolution</h2>
          <Paragraph isMobile={isMobile}>
            <strong>10.1 Governing Law:</strong> These Terms shall be governed by and construed in
            accordance with the laws of the State of Delaware, without regard to its conflict of law
            principles.
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            <strong>10.2 Dispute Resolution:</strong> Any disputes arising out of or related to these
            Terms shall be resolved through arbitration in Wilmington, Delaware, in accordance with
            the rules of the American Arbitration Association.
          </Paragraph>
        </Section>

        <Section isMobile={isMobile}>
          <h2>11. Contact Information</h2>
          <Paragraph isMobile={isMobile}>
            If you have any questions or concerns about these Terms of Service, you can contact us at:
          </Paragraph>
          <Paragraph isMobile={isMobile}>
            Email: <FooterLink href="mailto:info@riskmaster.pro">info@riskmaster.pro</FooterLink>
          </Paragraph>
        </Section>
      </TermsContainer>
      <Footer />
    </>
  );
};

export default TermsOfServicePage;
