// src/utils/afidHandler.ts

const AFID_KEY = 'afid';
const AFID_TIMESTAMP_KEY = 'afid_timestamp';
const AFID_DAYS = 60;
const EXPIRATION_PERIOD = AFID_DAYS * 24 * 60 * 60 * 1000; // 60 days in milliseconds

/**
 * Stores the afid and current timestamp in localStorage.
 * @param afid - The affiliate ID to store.
 */
export function storeAfid(afid: string) {
  const now = Date.now();
  localStorage.setItem(AFID_KEY, afid);
  localStorage.setItem(AFID_TIMESTAMP_KEY, now.toString());
}

/**
 * Retrieves the valid afid from localStorage if it hasn't expired.
 * If expired, it removes the afid from storage.
 * @returns The valid afid or null if expired or not found.
 */
export function getValidAfid(): string | null {
  const afid = localStorage.getItem(AFID_KEY);
  const afidTimestamp = localStorage.getItem(AFID_TIMESTAMP_KEY);
  const now = Date.now();

  if (afid && afidTimestamp) {
    const timeElapsed = now - parseInt(afidTimestamp, 10);

    if (timeElapsed <= EXPIRATION_PERIOD) {
      // afid is still valid
      return afid;
    } else {
      // afid has expired; remove it
      removeAfid();
    //   console.log('Affiliate ID has expired and has been removed.');
    }
  }
  return null;
}

/**
 * Removes the afid and its timestamp from localStorage.
 */
export function removeAfid() {
  localStorage.removeItem(AFID_KEY);
  localStorage.removeItem(AFID_TIMESTAMP_KEY);
}
