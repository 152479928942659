// src/pages/ProfileEdit.tsx

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { NotificationContext } from '../contexts/NotificationContext'; // Import the NotificationContext
import { jwtDecode } from 'jwt-decode'; // Updated import
import { Helmet } from 'react-helmet-async';

const ProfileEditContainer = styled.div`
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--color-background);
  color: var(--color-text);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 0.75rem;
  width: 100%;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  font-size: 1rem;
`;

const Button = styled.button`
  background-color: var(--color-primary);
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;

  &:hover {
    background-color: var(--color-secondary);
  }
`;

const BackButton = styled(Button)`
  background-color: var(--color-primary);

  &:hover {
    background-color: var(--color-secondary);
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 1rem;
`;

const SuccessMessage = styled.p`
  color: green;
  text-align: center;
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const ProfileEdit: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const navigate = useNavigate();

  // Access the Notification Context
  const { showNotification } = useContext(NotificationContext);

  // Function to check if the token is valid
  const isTokenValid = (token: string | null) => {
    if (!token) return false;
    try {
      const decoded: any = jwtDecode(token);
      const currentTime = Date.now() / 1000; // in seconds
      return decoded.exp && decoded.exp > currentTime;
    } catch (error) {
      console.error('Failed to decode token:', error);
      return false;
    }
  };

  // Fetch the user's existing profile data and populate the form
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');

      if (!isTokenValid(token)) {
        localStorage.removeItem('token');
        // showNotification('Session expired. Please log in again.');
        navigate('/');
        return;
      }

      try {
        const response = await axios.get('https://riskmaster.pro/fetch_user_data_proxy.php', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          localStorage.removeItem('token');
          // showNotification('Session expired. Please log in again.');
          navigate('/');
          return;
        }

        if (response.data.status === 'success') {
          const data = response.data.user;
          setName(data.fullName);
          setEmail(data.email);
        } else {
          showNotification(response.data.message || 'Error fetching profile data.');
        }
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          // showNotification('Session expired. Please log in again.');
          navigate('/');
        } else {
          showNotification('Error fetching profile data.');
          console.error(error);
        }
      }
    };

    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Validate passwords match
    if (newPassword && newPassword !== confirmNewPassword) {
      showNotification('New passwords do not match.');
      return;
    }

    const token = localStorage.getItem('token');

    if (!isTokenValid(token)) {
      localStorage.removeItem('token');
      // showNotification('Session expired. Please log in again.');
      navigate('/');
      return;
    }

    try {
      const response = await axios.post(
        'https://riskmaster.pro/update_profile_proxy.php', // Backend API to update profile
        {
          name,
          email,
          oldPassword,
          newPassword: newPassword || undefined, // Only send newPassword if it's provided
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 401) {
        localStorage.removeItem('token');
        // showNotification('Session expired. Please log in again.');
        navigate('/');
        return;
      }

      if (response.data.status === 'success') {
        showNotification('Profile updated successfully.');
        navigate('/account');
      } else {
        showNotification(response.data.message || 'Error updating profile.');
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        // showNotification('Session expired. Please log in again.');
        navigate('/');
      } else {
        showNotification('Error updating profile.');
        console.error(error);
      }
    }
  };

  const handleBack = () => {
    navigate('/account');
  };

  return (
    <ProfileEditContainer>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>Your Page Title</title>
      </Helmet>
      <h2>Edit Profile</h2>
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="name">Name</Label>
        <Input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your full name"
          required
        />

        <Label htmlFor="email">Email</Label>
        <Input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email address"
          required
        />

        <Label htmlFor="oldPassword">Old Password</Label>
        <Input
          type="password"
          id="oldPassword"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          placeholder="Enter your old password"
          required={!!newPassword}
        />

        <Label htmlFor="newPassword">New Password</Label>
        <Input
          type="password"
          id="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Enter your new password"
        />

        <Label htmlFor="confirmNewPassword">Confirm New Password</Label>
        <Input
          type="password"
          id="confirmNewPassword"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          placeholder="Confirm your new password"
        />

        <ButtonContainer>
          <BackButton type="button" onClick={handleBack}>
            Back to Account
          </BackButton>
          <Button type="submit">Save Changes</Button>
        </ButtonContainer>
      </Form>
    </ProfileEditContainer>
  );
};

export default ProfileEdit;
