// src/pages/AboutUs.tsx

import React, { useEffect } from 'react';
import styled from 'styled-components';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';

const AboutUsContainer = styled.div`
  background-color: var(--color-section-1-bg);
  color: var(--color-text);
  padding: 2rem 0;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 8rem 8rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 1rem 1.5rem;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: white;
  text-align: center;
`;

const Description = styled.div`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: #ccc;
  max-width: 100%;

  p {
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    font-size: 1rem;
    line-height: 1.6;
  }
`;

const ContactSection = styled.div`
  margin-top: 2rem;
  color: #ccc;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 400px;
  margin-top: 2rem;

  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
`;

interface AboutUsProps {
  isLoggedIn: boolean;
  onLogout: () => void;
}

const AboutUs: React.FC<AboutUsProps> = ({ isLoggedIn, onLogout }) => {

  // Meta Pixel: Track Page View on AboutUs Load
  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'ViewContent', { page: 'AboutUs' });
    }
  }, []);

  // Meta Pixel: Track Contact Info Click
  const handleContactClick = () => {
    if (window.fbq) {
      window.fbq('track', 'ContactInfoClick', { page: 'AboutUs' });
    }
  };

  return (
    <AboutUsContainer>
      <Helmet>
        <title>About Us | RiskMaster</title>
        <meta
          name="description"
          content="Learn about RiskMaster, our mission, and how we help traders achieve consistency through automated risk management."
        />
        <link rel="canonical" href="https://www.riskmaster.pro/about-us" />
      </Helmet>
      <Navigation
        activeSection="about-us"
        handleScroll={() => {}}
        isLoggedIn={isLoggedIn}
        onLogout={onLogout}
      />
      <ContentWrapper>
        <Title>About Us</Title>
        <Description>
          <p>
            At M.S Venture LLC, we are two traders who have walked the challenging path of the trading
            world. We've felt the sting of losses, the frustration of watching well-planned strategies
            crumble under the weight of emotions, and the disheartening cycle of hope followed by
            disappointment. We know what it's like to feel discouraged in a game where success often
            seems out of reach.
          </p>
          <p>
            Our journey began much like yours—driven by ambition but hindered by the unpredictable nature
            of emotions in high-stakes environments. We found ourselves making impulsive decisions,
            deviating from our strategies, and facing the consequences. But rather than accept this as an
            unchangeable reality, we decided to transform our setbacks into stepping stones toward
            consistency.
          </p>
          <p>
            Determined to break free from the cycle of inconsistency, we developed a solution that would
            help us regain control over our trading decisions. The result is a program designed to manage
            trading risks through automated, predefined safeguards. By integrating this tool into our
            trading routine, we were able to remove the emotional pitfalls that once held us back.
          </p>
          <p>
            Our mission is to extend this lifeline to fellow traders who are struggling to stay
            disciplined. We believe that every trader has untapped potential waiting to be realized. Our
            software isn't just about numbers and algorithms; it's about empowering you to reclaim your
            strategy, rebuild your confidence, and achieve the consistency you've been striving for.
          </p>
          <p>
            We invite you to join us on this journey toward greater consistency in trading. Let’s turn the
            page together and transform those losses into lessons, and those lessons into steady
            improvement.
          </p>
        </Description>
        <ContactSection>
          <h2>Contact Information</h2>
          <p>
            <strong>Company:</strong> M.S Venture LLC
            <br />
            <strong>Address:</strong> 1207 Delaware Ave, #1131, Wilmington, DE 19806
            <br />
            <strong>Email:</strong>{' '}
            <a href="mailto:support@riskmaster.pro" onClick={handleContactClick}>
              support@riskmaster.pro
            </a>
          </p>
        </ContactSection>
        <MapContainer>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4944.6781586263!2d-75.55715270790176!3d39.75745747671473!2m3!1f0!2f0!3f0!3m2!
            1i1024!2i768!4f13.1!3m3!1m2!1s0x89c70384c5c5a5b3%3A0x4d52d49b3e8337e6!2s1207%20Delaware%20Ave%20%231131%2C%20Wilmington%2C%20DE%2019806%2C%20USA!
            5e0!3m2!1sen!2sil!4v1633368362291!5m2!1sen!2sil"
            title="Our Location"
            loading="lazy"
            allowFullScreen
          ></iframe>
        </MapContainer>
      </ContentWrapper>
      <Footer />
    </AboutUsContainer>
  );
};

export default AboutUs;
