// src/pages/PrivacyPolicyPage.tsx

import React from 'react';
import styled, { css } from 'styled-components';
import useIsMobile from '../hooks/useIsMobile';
import { Helmet } from 'react-helmet-async';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

interface ResponsiveProps {
  isMobile: boolean;
}

const Container = styled.div<ResponsiveProps>`
  max-width: 1200px;
  margin: 0 auto;
  padding: 12rem 2rem 2rem; /* Added 12rem top padding */
  color: var(--color-text);
  background-color: var(--color-background);
  ${({ isMobile }) =>
    isMobile &&
    css`
      text-align: center;
      padding: 0rem 2rem 2rem;
    `}
  line-height: 1.6;

  p,
  ul {
    margin-bottom: 1.5rem;
  }
`;

const Title = styled.h1<ResponsiveProps>`
  color: var(--color-primary);
  text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
  margin-bottom: 2rem;
`;

const Section = styled.section<ResponsiveProps>`
  margin-bottom: 2rem;

  h2 {
    color: var(--color-secondary);
    margin-bottom: 1rem;
    text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
  }

  p {
    text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
  }

  ul {
    text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
  }
`;

const Paragraph = styled.p<ResponsiveProps>`
  line-height: 1.6;
  margin-bottom: 1rem;
  text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
`;

const List = styled.ul<ResponsiveProps>`
  list-style-type: disc;
  ${({ isMobile }) =>
    isMobile
      ? css`
          margin-left: 0;
          padding-left: 0;
          text-align: center;
        `
      : css`
          margin-left: 1.5rem;
          padding-left: 1.5rem;
        `}

  li {
    margin-bottom: 0.5rem;
    ${({ isMobile }) =>
      isMobile &&
      css`
        text-align: center;
      `}
  }

  a {
    color: var(--color-primary);
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
      color: var(--color-secondary);
    }
  }
`;

const ContactLink = styled.a`
  color: var(--color-primary);
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
    color: var(--color-secondary);
  }
`;

const PrivacyPolicyPage: React.FC = () => {
  const isMobile = useIsMobile();

  const handleContactClick = () => {
    window.location.href = 'mailto:info@riskmaster.pro';
  };

  return (
    <>
      <Helmet>
        <title>Privacy Policy | RiskMaster</title>
        <meta
          name="description"
          content="Learn about RiskMaster's commitment to data privacy and security."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://www.riskmaster.pro/privacy-policy" />
      </Helmet>
      <Navigation
        activeSection="privacy-policy"
        handleScroll={() => {}}
        isLoggedIn={false} // Update this based on your authentication logic
        onLogout={() => {}} // Update this based on your authentication logic
      />
      <Container isMobile={isMobile}>
        <Title isMobile={isMobile}>Privacy Policy</Title>

        <Section isMobile={isMobile}>
          <h2>Introduction</h2>
          <Paragraph isMobile={isMobile}>
            M.S Venture LLC ("Company," "we," "us," or "our") is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your
            information when you visit our website and use our software extension for NinjaTrader
            ("Software"). Please read this Privacy Policy carefully. If you do not agree with the
            terms of this Privacy Policy, please do not access the site or use the Software.
          </Paragraph>
        </Section>

        <Section isMobile={isMobile}>
          <h2>Information We Collect</h2>
          <Paragraph isMobile={isMobile}>
            <strong>Personal Information:</strong> We may collect personal information that you
            voluntarily provide to us when you register on the website, make a purchase,
            participate in the affiliate program, or send a support ticket. This information may
            include:
          </Paragraph>
          <List isMobile={isMobile}>
            <li>Email address</li>
            <li>Login credentials</li>
            <li>Affiliate program coupon codes</li>
            <li>Subscription type and details</li>
            <li>
              Payment information (e.g., credit card number, expiration date, and billing address)
            </li>
            <li>Any other information you choose to provide to us</li>
          </List>
          <Paragraph isMobile={isMobile}>
            <strong>Usage Data:</strong> When you use the Software, it automatically sends logs of
            your actions to our support team for debugging and analysis purposes. This includes all
            actions related to the Software's usage, such as:
          </Paragraph>
          <List isMobile={isMobile}>
            <li>Software version and configuration</li>
            <li>Operating system and device information</li>
            <li>Error logs and crash reports</li>
            <li>Usage patterns and statistics</li>
          </List>
          <Paragraph isMobile={isMobile}>
            <strong>Cookies and Tracking Technologies:</strong> We use cookies and other tracking
            technologies to collect information about your interactions with our website and Software.
            This information may include:
          </Paragraph>
          <List isMobile={isMobile}>
            <li>Browser type and version</li>
            <li>Device type and operating system</li>
            <li>IP address and location</li>
            <li>Referring URL and pages visited</li>
            <li>Time spent on our website and Software</li>
          </List>
        </Section>

        <Section isMobile={isMobile}>
          <h2>Use of Your Information</h2>
          <Paragraph isMobile={isMobile}>We use the information we collect in the following ways:</Paragraph>
          <List isMobile={isMobile}>
            <li>To create and manage your account.</li>
            <li>To process your transactions through our third-party payment processor, Agile.net.</li>
            <li>To track affiliate program usage.</li>
            <li>To provide customer support and respond to your inquiries.</li>
            <li>To send you updates, newsletters, and marketing communications.</li>
            <li>To analyze and improve our website and Software.</li>
            <li>To prevent and detect fraud, abuse, and other malicious activities.</li>
          </List>
        </Section>

        <Section isMobile={isMobile}>
          <h2>Disclosure of Your Information</h2>
          <Paragraph isMobile={isMobile}>
            We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
          </Paragraph>
          <List isMobile={isMobile}>
            <li>
              <strong>By Law or to Protect Rights:</strong> If we believe the release of information
              about you is necessary to respond to legal process, to investigate or remedy potential
              violations of our policies, or to protect the rights, property, and safety of others, we
              may share your information as permitted or required by any applicable law, rule, or
              regulation.
            </li>
            <li>
              <strong>Third-Party Service Providers:</strong> We may share your information with third
              parties that perform services for us or on our behalf, such as payment processing
              through Agile.net, email marketing services, and customer support software.
            </li>
            <li>
              <strong>Affiliates:</strong> We may share your information with our affiliates, in which
              case we will require those affiliates to honor this Privacy Policy.
            </li>
            <li>
              <strong>Business Transfers:</strong> We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of company assets, financing,
              or acquisition of all or a portion of our business to another company.
            </li>
            <li>
              <strong>With Your Consent:</strong> We may share your information with third parties if
              you have given us your consent to do so.
            </li>
          </List>
        </Section>

        <Section isMobile={isMobile}>
          <h2>Data Security</h2>
          <Paragraph isMobile={isMobile}>
            We use administrative, technical, and physical security measures to help protect your
            personal information. While we have taken reasonable steps to secure the personal
            information you provide to us, please be aware that no security measures are perfect or
            impenetrable, and no method of data transmission can be guaranteed against interception
            or misuse.
          </Paragraph>
        </Section>

        <Section isMobile={isMobile}>
          <h2>Age Restriction</h2>
          <Paragraph isMobile={isMobile}>
            The Company does not knowingly collect information from children under the age of 18.
            If we learn that we have collected information from a child under age 18, we will delete
            that information as quickly as possible. If you believe that we might have any information
            from or about a child under 18, please{' '}
            <ContactLink onClick={handleContactClick}>contact us</ContactLink>.
          </Paragraph>
        </Section>

        <Section isMobile={isMobile}>
          <h2>Your Rights</h2>
          <List isMobile={isMobile}>
            <li>
              <strong>Right to Access:</strong> You have the right to access and review your
              personal information.
            </li>
            <li>
              <strong>Right to Correction:</strong> You have the right to correct inaccuracies in
              your personal information.
            </li>
            <li>
              <strong>Right to Deletion:</strong> You have the right to request the deletion of
              your personal information.
            </li>
            <li>
              <strong>Right to Restrict Processing:</strong> You have the right to restrict the
              processing of your personal information.
            </li>
            <li>
              <strong>Right to Data Portability:</strong> You have the right to request a copy of
              your personal information in a machine-readable format.
            </li>
          </List>
        </Section>

        <Section isMobile={isMobile}>
          <h2>Changes to This Privacy Policy</h2>
          <Paragraph isMobile={isMobile}>
            We may update this Privacy Policy from time to time. If we make any changes to this Privacy Policy, we will post the updated version on our website and notify you by email or through a notification on our website.
          </Paragraph>
        </Section>

        <Section isMobile={isMobile}>
          <ContactLink onClick={handleContactClick}>Contact Us</ContactLink>
        </Section>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
